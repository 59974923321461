import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import React from "react";
import { Facebook, Linkedin, Youtube } from "react-feather";

const FooterSection = dynamic(() => import("./FooterSection"));
const FooterLink = dynamic(() => import("./FooterLink"));
const FooterSocialLink = dynamic(() => import("./FooterSocialLink"));

function Footer() {
  const { t } = useTranslation("layout");

  return (
    <footer className="py-12 bg-white sm:pt-16 lg:pt-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 mt-16 sm:grid-cols-3 gap-y-16 lg:grid-cols-6 gap-x-16">
          <FooterSection title="Jobs">
            <FooterLink href="https://help.smarketer.jobs/">
              Knowledge-Base
            </FooterLink>
            <FooterLink href="/pricing">Preise</FooterLink>
            <FooterLink href="/blog">Blog</FooterLink>
            <FooterLink href="/account/jobs">Meine Jobs</FooterLink>
          </FooterSection>
          <FooterSection title={t("footer.legal")}>
            <FooterLink href="/legal/impressum">
              {t("footer.imprint")}
            </FooterLink>
            <FooterLink href="https://www.smarketer.de/agb-smarketer-jobs/">
              {t("footer.terms")}
            </FooterLink>
            <FooterLink href="/legal/privacy">{t("footer.privacy")}</FooterLink>
            <FooterLink href="/legal/licenses">Lizenzen</FooterLink>
          </FooterSection>
          <FooterSection title="Smarketer">
            <FooterLink href="https://www.smarketer.de/agentur/ueber-uns/">
              {t("footer.aboutUs")}
            </FooterLink>
            <FooterLink href="https://www.smarketer.de/agentur/team/">
              {t("footer.team")}
            </FooterLink>
            <FooterLink href="https://www.smarketer.de/google-adwords-agentur/">
              {t("footer.agency")}
            </FooterLink>
            <FooterLink href="https://www.smarketer.de/blog/">
              {t("footer.blog")}
            </FooterLink>
          </FooterSection>

          <div className="col-span-2 sm:col-span-3 xl:pl-20">
            <h6 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">
              {t("footer.contact")}
            </h6>
            <div className="grid grid-cols-1 mt-8 gap-y-8 sm:grid-cols-2 sm:gap-x-16">
              <div>
                <h6 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">
                  {t("footer.byPhone")}
                </h6>
                <p className="mt-2.5 text-xl font-pj text-gray-900 font-bold">
                  <a href="tel:+49 (0) 30 577 008 136">
                    +49 (0) 30 577 008 136
                  </a>
                </p>
              </div>
              <div>
                <h6 className="text-sm font-bold tracking-widest text-gray-900 uppercase font-pj">
                  {t("footer.byEmail")}
                </h6>
                <p className="mt-2.5 text-xl font-pj text-gray-900 font-bold">
                  <a href="mailto:info@smarketer.de"> info@smarketer.de </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-16 border-gray-200" />
        <div className="mt-8 sm:flex sm:items-center sm:justify-between">
          <ul className="flex items-center justify-start space-x-3 sm:order-2 sm:justify-end">
            <FooterSocialLink
              href="https://www.linkedin.com/company/2682818/"
              aria-label="LinkedIn"
            >
              <Linkedin />
            </FooterSocialLink>
            <FooterSocialLink
              href="https://www.facebook.com/Smarketer/"
              aria-label="Facebook"
            >
              <Facebook />
            </FooterSocialLink>
            <FooterSocialLink
              href="https://www.youtube.com/channel/UC9gArL8WsL1xSuiQUNrHFYg"
              aria-label="Youtube"
            >
              <Youtube />
            </FooterSocialLink>
          </ul>
          <p className="mt-8 text-sm font-normal text-gray-600 font-pj sm:order-1 sm:mt-0">
            © Copyright {new Date().getFullYear()} Smarketer GmbH
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
