import React from "react";
import {
  Burger,
  Container,
  Group,
  Header as MantineHeader,
  Paper,
  Transition,
} from "@mantine/core";
import { useDisclosure, useClickOutside } from "@mantine/hooks";
import Link from "next/link";
import { useRouter } from "next/router";
import Image from "next/legacy/image";
import {
  LOGGED_IN_LINKS,
  LOGGED_OUT_LINKS,
  STATIC_LINKS,
} from "./HeaderConfig";
import { useGetCurrentUserQuery } from "@lib/store/slices/user";
import { useTranslation } from "next-i18next";

import logoImage from "@assets/images/logo.png";
import wordArtImage from "@assets/images/wordart.svg";

function Header() {
  const [opened, updateOpened] = useDisclosure(false);
  const ref = useClickOutside(() => updateOpened.close());
  const router = useRouter();
  const { data: user } = useGetCurrentUserQuery();
  const useDarkTheme = false;

  const { t } = useTranslation("layout");

  let links = STATIC_LINKS;
  if (user) {
    links = [...links, ...LOGGED_IN_LINKS];
  } else {
    links = [...links, ...LOGGED_OUT_LINKS];
  }

  const items = links.map((link) => (
    <Link
      key={link.label}
      href={link.link}
      className={`block p-3 md:p-2 rounded decoration-none text-sm font-medium hover:bg-slate-200 hover:text-slate-800 duration-100 ${
        router.route === link.link && !("highlighted" in link)
          ? "bg-slate-100 text-slate-800"
          : ""
      }
      ${"highlighted" in link ? "bg-slate-800 text-white" : ""}
      `}
      onClick={() => updateOpened.close()}
    >
      {t(link.label)}
    </Link>
  ));

  return (
    <MantineHeader
      className={
        useDarkTheme ? "bg-slate-900 text-slate-200 border-slate-700" : ""
      }
      height={60}
    >
      <Container className="flex justify-between items-center h-full">
        <Link
          href="/"
          className="flex items-center text-lg font-bold gap-3 font-helvetica"
        >
          <Image
            src={logoImage}
            width={30}
            height={30}
            alt="Smarketer Jobs Logo"
            priority
          />
          <Image
            src={wordArtImage}
            width={80}
            height={30}
            alt="Smarketer Jobs Wordmark"
            priority
          />
        </Link>
        <Group spacing={5} className={`hidden md:flex gap-3`}>
          {items}
        </Group>

        <Burger
          opened={opened}
          onClick={() => updateOpened.toggle()}
          size="sm"
          className="md:hidden"
          color={useDarkTheme ? "white" : "black"}
          aria-label={opened ? t("header.closeMenu") : t("header.openMenu")}
        />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper
              className="absolute top-3 left-0 w-full p-5 z-10 flex flex-col gap-4"
              withBorder
              style={styles}
              ref={ref}
            >
              {items}
            </Paper>
          )}
        </Transition>
      </Container>
    </MantineHeader>
  );
}

export default Header;
