import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type Subscription = {
  id: string;
  max_companies: number;
  max_jobs: number;
  max_job_import_sources: number;
  name: string;
  created_at: string;
  updated_at: string;
};

export const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getOwnSubscription: builder.query<Subscription, void>({
      query: () => "/billing/subscriptions",
    }),
  }),
});

export const { useGetOwnSubscriptionQuery } = billingApi;
