export const STATIC_LINKS = [
  {
    link: "/",
    label: "header.home",
  },
  {
    link: "/faq",
    label: "header.faq",
  },
  {
    link: "https://help.smarketer.jobs",
    label: "header.support",
  },
  {
    link: "/blog",
    label: "header.blog",
  },
  {
    link: "/pricing",
    label: "header.pricing",
  },
];

export const LOGGED_OUT_LINKS = [
  {
    link: "/account/auth/login",
    label: "header.login",
  },
  {
    link: "/account/auth/register",
    label: "header.register",
    highlighted: true,
  },
];

export const LOGGED_IN_LINKS = [
  {
    link: "/account/jobs",
    label: "header.yourJobs",
    highlighted: true,
  },
  {
    link: "/account/auth/logout",
    label: "header.logout",
  },
];
