import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type StatusInfo = {
  status: string;
};

export type GiftMonthsRequest = {
  months: number;
  email: string;
  plan: string;
  secret: string;
};
export type SmarketerPromotionRequest = {
  email: string;
  plan: string;
  secret: string;
};

export const couponApi = createApi({
  reducerPath: "couponApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    giftMonths: builder.mutation<StatusInfo, GiftMonthsRequest>({
      query: (request) => ({
        url: `/coupon/gift-months`,
        method: "POST",
        body: request,
        headers: {
          Accept: "application/json",
        },
      }),
    }),
    sendSmarketerPromotion: builder.mutation<
      StatusInfo,
      SmarketerPromotionRequest
    >({
      query: (request) => ({
        url: `/coupon/smarketer-promotion`,
        method: "POST",
        body: request,
        headers: {
          Accept: "application/json",
        },
      }),
    }),
  }),
});

export const { useGiftMonthsMutation, useSendSmarketerPromotionMutation } =
  couponApi;
