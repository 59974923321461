import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { JobListing } from "@lib/types/jobTypes";
import { DEFAULT_HEADERS } from "../utils";
import { PerformanceReport } from "@lib/types/performance";

export type JobRecommendations = {
  company: JobListing[];
  similar: JobListing[];
};

export type JobWithPerformance = JobListing & {
  day_performances: PerformanceReport;
};

export const jobsApi = createApi({
  reducerPath: "jobsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    credentials: "include",
    headers: DEFAULT_HEADERS,
  }),
  tagTypes: ["ownJobs"],
  endpoints: (builder) => ({
    getJob: builder.query<JobListing, string>({
      query: (id) => `/jobs/${id}`,
    }),

    getOwnJobs: builder.query<JobListing[], void>({
      query: () => "/jobs",
      providesTags: ["ownJobs"],
    }),

    saveJob: builder.mutation<JobListing, JobListing>({
      query: (job) => ({
        url: `/jobs${job.id ? `/${job.id}` : ""}`,
        method: job.id ? "PUT" : "POST",
        body: job,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownJobs"],
    }),

    deleteJob: builder.mutation<void, JobListing>({
      query: (job) => ({
        url: `/jobs/${job.id}`,
        method: "DELETE",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownJobs"],
    }),

    copyJob: builder.mutation<JobListing, JobListing>({
      query: (job) => ({
        url: `/jobs/${job.id}/copy`,
        method: "POST",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownJobs"],
    }),

    publishJob: builder.mutation<void, string>({
      query: (job) => ({
        url: `/jobs/${job}/publish`,
        method: "PATCH",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownJobs"],
    }),

    unpublishJob: builder.mutation<void, string>({
      query: (job) => ({
        url: `/jobs/${job}/unpublish`,
        method: "PATCH",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownJobs"],
    }),

    getJobRecommendations: builder.query<JobRecommendations, JobListing>({
      query: (job) => ({
        url: `/jobs/${job.id}/recommendations`,
        method: "GET",
        headers: DEFAULT_HEADERS,
      }),
    }),

    getJobPerformance: builder.query<PerformanceReport, string>({
      query: (job) => ({
        url: `/jobs/${job}/performance`,
        method: "GET",
        headers: DEFAULT_HEADERS,
      }),
    }),

    getAccountPerformance: builder.query<JobWithPerformance[], void>({
      query: () => ({
        url: `/user/performance`,
        method: "GET",
        headers: DEFAULT_HEADERS,
      }),
    }),
  }),
});

export const {
  useGetJobQuery,
  useGetOwnJobsQuery,
  useSaveJobMutation,
  useDeleteJobMutation,
  useCopyJobMutation,
  usePublishJobMutation,
  useUnpublishJobMutation,
  useGetJobRecommendationsQuery,
  useGetJobPerformanceQuery,
  useGetAccountPerformanceQuery,
} = jobsApi;
