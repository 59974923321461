import { AppShell } from "@mantine/core";
import React from "react";
import useScrollContainerToTopOnNavigate from "@lib/hooks/useScrollContainerToTopOnNavigate";
import SeoTags from "./SeoTags";
import EnsureTranslationsArePresent from "./EnsureTranslationsArePresent";
import { useRouter } from "next/router";
import Script from "next/script";

import Header from "./Header/Header";
import Footer from "./Footer/Footer";

function Layout({ children }: { children: React.ReactNode }) {
  const containerRef = React.useRef<HTMLDivElement>(null);
  useScrollContainerToTopOnNavigate(containerRef);

  const router = useRouter();
  if (router.pathname === "/jobs/embed/[id]") {
    return <>{children}</>;
  }

  return (
    <div
      className="h-screen w-screen snap-y snap-proximity overflow-y-auto font-sans"
      ref={containerRef}
    >
      <SeoTags />
      <EnsureTranslationsArePresent />

      <AppShell padding={0} header={<Header />} footer={<Footer />}>
        <div className="bg-slate-50 h-full">{children}</div>
      </AppShell>

      <Script
        strategy="lazyOnload"
        src="https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/2095b3d096224819ba898fe0a41490308ea5c7d8ef034fbc8ae833d56f923a38.js"
      />
    </div>
  );
}

export default Layout;
