import React from "react";

const HOTJAR_SCRIPT = `
  (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:3280644,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
`;

function HotJarTag() {
  return (
    <script
      async
      defer
      className="cmplazyload"
      data-cmp-vendor="s46"
      type="text/plain"
      id="hotjar-script"
      dangerouslySetInnerHTML={{ __html: HOTJAR_SCRIPT }}
    />
  );
}

export default HotJarTag;
