import { useEffect } from "react";
import { useRouter } from "next/router";
import {
  startNavigationProgress,
  resetNavigationProgress,
  NavigationProgress as ProgessElement,
  stopNavigationProgress,
} from "@mantine/nprogress";

declare global {
  interface Window {
    nprogressTimeout: NodeJS.Timeout;
  }
}

// Based on https://mantine.dev/others/nprogress/#usage-with-nextjs
function NavigationProgress() {
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        if (window.nprogressTimeout) {
          clearTimeout(window.nprogressTimeout);
        }
        window.nprogressTimeout = setTimeout(() => {
          stopNavigationProgress();
        }, 5000);

        startNavigationProgress();
      }
    };
    const handleComplete = () => {
      clearTimeout(window.nprogressTimeout);
      resetNavigationProgress();
    };

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router.asPath]);

  return <ProgessElement />;
}

export default NavigationProgress;
