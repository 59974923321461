import Head from "next/head";
import React from "react";

function RobotsTag({ shouldIndex }: { shouldIndex: boolean }) {
  return (
    <Head>
      <meta
        name="robots"
        content={shouldIndex ? "follow, index" : "follow, noindex"}
        key="robots"
      />
    </Head>
  );
}

export default RobotsTag;
