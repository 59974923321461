import { AppProps } from "next/app";
import Head from "next/head";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { Provider as ReduxProvider } from "react-redux";
import Layout from "@components/Layout/Layout";
import { store } from "@lib/store";
import mantineCache from "@lib/utils/mantineCache";
import { appWithTranslation } from "next-i18next";
import "@styles/globals.css";

import "@fontsource/inter/variable.css";
import { initCsrfSession } from "@lib/actions/auth/initCsrfSession";
import dynamic from "next/dynamic";
import NavigationProgress from "@components/Landing/NavigationProgress";
import CookieConsent from "@components/Various/Analytics/ConsentManagerNet/CookieConsent";
import HotJarTag from "@components/Various/Analytics/HotJarTag";

const AnalyticsGlobalTag = dynamic(
  () => import("@components/Various/Analytics/AnalyticsGlobalTag")
);
initCsrfSession();

function App(props: AppProps) {
  const { Component, pageProps } = props;

  return (
    <>
      <Head>
        <title>Smarketer Jobs</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Head>

      <ReduxProvider store={store}>
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: "light",
            primaryColor: "blue",

            components: {
              Text: {
                classNames: {
                  root: "break-words whitespace-normal",
                },
              },
              Badge: {
                classNames: {
                  root: "normal-case",
                },
              },
            },
          }}
          emotionCache={mantineCache}
        >
          <NavigationProgress />

          <NotificationsProvider>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </NotificationsProvider>
        </MantineProvider>

        <AnalyticsGlobalTag />
        <CookieConsent />
        <HotJarTag />
      </ReduxProvider>
    </>
  );
}
export default appWithTranslation(App);
