import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
import getOriginUrl from "@lib/utils/url/getOriginUrl";
import RobotsTag from "../Various/Seo/RobotsTag";
import StructuredDataTag from "../Various/Seo/StructuredDataTag";
import { useTranslation } from "next-i18next";

function SeoTags() {
  const { t } = useTranslation("layout");
  const router = useRouter();
  if (router.route === "/404") {
    // No SEO on 404 error page
    return null;
  }

  const canonicalUrl = `${getOriginUrl()}/${router.locale}${router.asPath}`;

  return (
    <>
      <Head>
        <link rel="canonical" href={canonicalUrl} />

        {router.locales
          ?.filter((locale) => locale !== router.locale && locale !== "default")
          .map((locale) => (
            <link
              rel="alternate"
              hrefLang={locale}
              key={locale}
              href={`${getOriginUrl()}/${locale}${router.asPath}`}
            />
          ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`${getOriginUrl()}/de${router.asPath}`}
        />

        <link rel="icon" href="/assets/images/logo.png" />
        <meta
          name="description"
          key="description"
          content={t("seo.description")}
        />
      </Head>

      <RobotsTag shouldIndex={true} />

      <StructuredDataTag
        data={{
          "@context": "https://schema.org/",
          "@type": "WebSite",
          name: "Smarketer Jobs",
          url: getOriginUrl(),
          alternateName: "smarketer.jobs",
        }}
      />

      <StructuredDataTag
        data={{
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "Smarketer Jobs",
          url: getOriginUrl(),
          logo: `${getOriginUrl()}/assets/images/logo.png`,
          address: {
            "@type": "PostalAddress",
            streetAddress: "Alte Jakobstraße 83",
            addressLocality: "Berlin",
            addressRegion: "Berlin",
            postalCode: "10179",
            addressCountry: "Germany",
          },
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            email: "info@smarketer.de",
          },
        }}
      />

      <StructuredDataTag
        data={{
          "@context": "http://schema.org",
          "@type": "Organization",
          name: "Smarketer",
          legalName: "Smarketer GmbH",
          url: "https://www.smarketer.de/",
          foundingDate: "2011",
          founders: [
            {
              "@type": "Person",
              name: "David Gabriel",
            },
          ],
          address: {
            "@type": "PostalAddress",
            streetAddress: "Alte Jakobstraße 83",
            addressLocality: "Berlin",
            addressRegion: "Berlin",
            postalCode: "10179",
            addressCountry: "Germany",
          },
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            email: "info@smarketer.de",
          },
          sameAs: [
            "https://www.smarketer.de/",
            "https://www.linkedin.com/company/2682818/",
            "https://www.facebook.com/Smarketer/",
            "https://soundcloud.com/smarketer",
            "https://www.youtube.com/channel/UC9gArL8WsL1xSuiQUNrHFYg",
            "https://www.instagram.com/smarketer.de",
            "https://www.pinterest.de/SmarketerDE/",
            "https://www.kununu.com/de/smarketer",
          ],
        }}
      />
    </>
  );
}

export default SeoTags;
