import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type GuestViewStatus = {
  canUseView: boolean;
  isInView: boolean;
};

export const guestViewApi = createApi({
  reducerPath: "guestViewApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    getGuestViewStatus: builder.query<GuestViewStatus, void>({
      query: () => "/guest-view",
    }),

    openGuestView: builder.mutation<void, string>({
      query: (email) => ({
        url: `/guest-view/${encodeURIComponent(email)}`,
        method: "POST",
        headers: DEFAULT_HEADERS,
      }),
    }),

    closeGuestView: builder.mutation<void, void>({
      query: () => ({
        url: `/guest-view/close`,
        method: "POST",
        headers: DEFAULT_HEADERS,
      }),
    }),
  }),
});

export const {
  useGetGuestViewStatusQuery,
  useOpenGuestViewMutation,
  useCloseGuestViewMutation,
} = guestViewApi;
