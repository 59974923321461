import { AiResponse } from "@lib/types/jobTypes";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type JobTexts = {
  title: string;
  short_description: string;
  description: string;
  qualifications: string;
  benefits: string;
};
export type JobFeedback = {
  score: number;
  comment: string;
};
export type AiResponseReference = {
  response_id: string;
};
export type GenerateTextsRequest = {
  title: string;
  mood: string;
  description: string;
  requirements: string;
  benefits: string;
};
export const EMPTY_GENERATE_TEXTS_REQUEST = {
  title: "",
  mood: "",
  description: "",
  requirements: "",
  benefits: "",
};

export const jobAiApi = createApi({
  reducerPath: "jobAiApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
    credentials: "include",
  }),
  endpoints: (builder) => ({
    rankJobTexts: builder.mutation<AiResponseReference, JobTexts>({
      query: (texts) => ({
        url: `/jobs/rankTexts`,
        method: "POST",
        body: texts,
        headers: DEFAULT_HEADERS,
      }),
    }),
    improveText: builder.mutation<AiResponseReference, string>({
      query: (text) => ({
        url: `/jobs/improveText`,
        method: "POST",
        body: { text },
        headers: DEFAULT_HEADERS,
      }),
    }),
    generateTexts: builder.mutation<AiResponseReference, GenerateTextsRequest>({
      query: (body) => ({
        url: `/jobs/generateTexts`,
        method: "POST",
        body,
        headers: DEFAULT_HEADERS,
      }),
    }),
    getStatus: builder.query<AiResponse, string>({
      query: (id: string) => ({
        url: `/ai-responses/${id}`,
        method: "GET",
        headers: DEFAULT_HEADERS,
      }),
    }),
  }),
});

export const {
  useRankJobTextsMutation,
  useImproveTextMutation,
  useGenerateTextsMutation,
  useGetStatusQuery,
} = jobAiApi;
