import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CatalogData } from "@lib/hooks/useCatalog";
import { DEFAULT_HEADERS } from "../utils";

export type CountryInfo = {
  code: string;
  name_de: string;
  name_en: string;
};

export const catalogApi = createApi({
  reducerPath: "catalogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
  }),
  endpoints: (builder) => ({
    getCatalog: builder.query<CatalogData, void>({
      query: () => "/catalog",
    }),
    getCountryList: builder.query<CountryInfo[], void>({
      query: () => "/catalog/countries",
    }),
  }),
});

export const { useGetCatalogQuery, useGetCountryListQuery } = catalogApi;
