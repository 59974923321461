import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type PasswordResetRequest = {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
};

export const passwordApi = createApi({
  reducerPath: "passwordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    credentials: "include",
    headers: DEFAULT_HEADERS,
  }),
  endpoints: (builder) => ({
    requestPasswordReset: builder.mutation<void, string>({
      query: (email) => ({
        url: "/forgot-password",
        method: "POST",
        body: { email },
        headers: DEFAULT_HEADERS,
      }),
    }),

    resetPassword: builder.mutation<void, PasswordResetRequest>({
      query: (body) => ({
        url: "/reset-password",
        method: "POST",
        body,
        headers: DEFAULT_HEADERS,
      }),
    }),
  }),
});

export const { useRequestPasswordResetMutation, useResetPasswordMutation } =
  passwordApi;
