import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { apiToken } from "./slices/apiToken";
import { billingApi } from "./slices/billing";
import { catalogApi } from "./slices/catalog";
import { companiesApi } from "./slices/companies";
import { couponApi } from "./slices/coupon";
import { guestViewApi } from "./slices/guestView";
import { jobAiApi } from "./slices/jobAi";
import { jobImportSorceApi } from "./slices/jobImportSource";
import { jobsApi } from "./slices/jobs";
import { passwordApi } from "./slices/password";
import { userApi } from "./slices/user";

export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [billingApi.reducerPath]: billingApi.reducer,
    [passwordApi.reducerPath]: passwordApi.reducer,
    [guestViewApi.reducerPath]: guestViewApi.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [jobImportSorceApi.reducerPath]: jobImportSorceApi.reducer,
    [apiToken.reducerPath]: apiToken.reducer,
    [jobAiApi.reducerPath]: jobAiApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(jobsApi.middleware)
      .concat(catalogApi.middleware)
      .concat(companiesApi.middleware)
      .concat(billingApi.middleware)
      .concat(passwordApi.middleware)
      .concat(guestViewApi.middleware)
      .concat(jobImportSorceApi.middleware)
      .concat(jobAiApi.middleware)
      .concat(apiToken.middleware)
      .concat(couponApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type FormDataEntity = {
  id?: string;
  body: FormData;
};
