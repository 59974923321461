import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";

export type ApiToken = {
  id: string;
  name: string;
  abilities: string[];
  last_used_at: string;
  token: string;
  created_at: string;
  updated_at: string;
};

export const apiToken = createApi({
  reducerPath: "apiToken",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: DEFAULT_HEADERS,
    credentials: "include",
  }),
  tagTypes: ["apiTokens"],
  endpoints: (builder) => ({
    getApiTokens: builder.query<ApiToken[], void>({
      query: () => "/api-tokens",
      providesTags: ["apiTokens"],
    }),
    createApiToken: builder.mutation<ApiToken, string>({
      query: (name) => ({
        url: "/api-tokens",
        method: "POST",
        body: { name },
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["apiTokens"],
    }),
    deleteApiToken: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api-tokens/${id}`,
        method: "DELETE",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["apiTokens"],
    }),
  }),
});

export const {
  useGetApiTokensQuery,
  useCreateApiTokenMutation,
  useDeleteApiTokenMutation,
} = apiToken;
