import { useRouter } from "next/router";
import React, { useEffect } from "react";

export default function useScrollContainerToTopOnNavigate(
  ref: React.RefObject<HTMLDivElement>
) {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.scrollTo({ top: 0, behavior: "smooth" });
    };
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [ref, router]);
}
