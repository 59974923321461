import Head from "next/head";
import React from "react";

function StructuredDataTag({ data }: { data: any }) {
  return (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      ></script>
    </Head>
  );
}

export default StructuredDataTag;
