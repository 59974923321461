import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { DEFAULT_HEADERS } from "../utils";
import {
  JobImportSource,
  JobImportSourceWithRuns,
} from "@lib/types/jobImportTypes";

export const jobImportSorceApi = createApi({
  reducerPath: "jobImportSorceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    credentials: "include",
    headers: DEFAULT_HEADERS,
  }),
  tagTypes: ["ownSources", "source"],
  endpoints: (builder) => ({
    getJobImportSource: builder.query<JobImportSourceWithRuns, string>({
      query: (id) => `/job-import-sources/${id}`,
      providesTags: (result, error, arg) => [{ type: "source", id: arg }],
    }),

    getOwnJobImportSources: builder.query<JobImportSource[], void>({
      query: () => "/job-import-sources",
      providesTags: ["ownSources"],
    }),

    saveJobImportSource: builder.mutation<JobImportSource, JobImportSource>({
      query: (job) => ({
        url: `/job-import-sources${job.id ? `/${job.id}` : ""}`,
        method: job.id ? "PUT" : "POST",
        body: job,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "source", id: arg.id }],
    }),

    deleteJobImportSource: builder.mutation<void, JobImportSource>({
      query: (job) => ({
        url: `/job-import-sources/${job.id}`,
        method: "DELETE",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownSources"],
    }),

    runJobImportSource: builder.mutation<void, JobImportSource>({
      query: (job) => ({
        url: `/job-import-sources/${job.id}/run`,
        method: "POST",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: (result, error, arg) => [{ type: "source", id: arg.id }],
    }),
  }),
});

export const {
  useGetJobImportSourceQuery,
  useGetOwnJobImportSourcesQuery,
  useSaveJobImportSourceMutation,
  useDeleteJobImportSourceMutation,
  useRunJobImportSourceMutation,
} = jobImportSorceApi;
