import { useTranslation } from "next-i18next";
import React from "react";

function EnsureTranslationsArePresent() {
  const { t } = useTranslation("common");

  if (
    t("KEEP_KEY_UNTOUCHED") == "KEEP_KEY_UNTOUCHED" &&
    process.env.NODE_ENV == "development"
  ) {
    return (
      <div className="absolute top-0 w-screen h-screen p-4 bg-red-500 text-white z-50 flex items-center justify-center flex-col opacity-50">
        <h1 className="text-xl font-bold mb-3">
          Übersetzungen wurden nicht geladen!
        </h1>
        <p className="text-sm">
          Es konnten keine Übersetzungen im aktuellen Kontext gefunden werden.
          Bitte prüfe, dass diese Seite Server-Side Translations durch{" "}
          <pre className="inline-block">next-i18next</pre> exportiert hat.
        </p>
      </div>
    );
  }

  return null;
}

export default EnsureTranslationsArePresent;
