import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { OnboardingData } from "@components/Account/Onboarding/Onboarding";
import { AiUsage, Credentials, User } from "@lib/types/user";
import { DEFAULT_HEADERS } from "../utils";

const CURRENT_USER_TAG = "currentUser";

export const userApi = createApi({
  reducerPath: "userApi",

  // Frequently refetch to make sure we find out if the
  // session has expired.
  refetchOnFocus: true,
  refetchOnReconnect: true,

  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    credentials: "include",
    headers: DEFAULT_HEADERS,
  }),
  tagTypes: [CURRENT_USER_TAG],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<User, void>({
      query: () => ({
        url: `/user`,
        headers: DEFAULT_HEADERS,
      }),
      providesTags: [CURRENT_USER_TAG],
    }),

    completeOnboarding: builder.mutation<void, OnboardingData>({
      query: (data) => ({
        url: `/user/onboarding`,
        method: "POST",
        body: data,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    updateUser: builder.mutation<User, Partial<User>>({
      query: (data) => ({
        url: `/user`,
        method: "PATCH",
        body: data,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    login: builder.mutation<User, Credentials>({
      query: (credentials) => ({
        url: `/login`,
        method: "POST",
        body: credentials,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    register: builder.mutation<User, Credentials>({
      query: (credentials) => ({
        url: `/register`,
        method: "POST",
        body: credentials,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    deleteUser: builder.mutation<void, void>({
      query: (credentials) => ({
        url: `/user`,
        method: "DELETE",
        body: credentials,
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    logout: builder.mutation<User, void>({
      query: () => ({
        url: `/logout`,
        method: "POST",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: [CURRENT_USER_TAG],
    }),

    getAiUsage: builder.query<AiUsage[], void>({
      query: () => ({
        url: `/user/ai-usage`,
        headers: DEFAULT_HEADERS,
      }),
      providesTags: [CURRENT_USER_TAG],
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useUpdateUserMutation,
  useLoginMutation,
  useRegisterMutation,
  useDeleteUserMutation,
  useLogoutMutation,
  useCompleteOnboardingMutation,
  useGetAiUsageQuery,
} = userApi;
