import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { FormDataEntity } from "..";
import { Company } from "@lib/types/jobTypes";
import { DEFAULT_HEADERS } from "../utils";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_BACKEND_URL,
    credentials: "include",
    headers: DEFAULT_HEADERS,
  }),
  tagTypes: ["ownCompanies"],
  endpoints: (builder) => ({
    getOwnCompanies: builder.query<Company[], void>({
      query: () => "/companies",
      providesTags: ["ownCompanies"],
    }),

    saveCompany: builder.mutation<Company, FormDataEntity>({
      query: (company) => ({
        url: `/companies${company.id ? `/${company.id}` : ""}`,
        method: "POST",
        body: company.body,
        headers: {
          Accept: "application/json",
        },
      }),
      invalidatesTags: ["ownCompanies"],
    }),

    deleteCompany: builder.mutation<void, Company>({
      query: (job) => ({
        url: `/companies/${job.id}`,
        method: "DELETE",
        headers: DEFAULT_HEADERS,
      }),
      invalidatesTags: ["ownCompanies"],
    }),
  }),
});

export const {
  useGetOwnCompaniesQuery,
  useSaveCompanyMutation,
  useDeleteCompanyMutation,
} = companiesApi;
